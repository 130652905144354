<template>
	<edit-template ref="edit" class="role-edit" @confirm="save('form')" @cancel="open" :cancelRemind="false" confirmText="保存" cancelText="取消">
		
    <!-- 大表单 -->
    <div class="form-body">
      <el-form ref="form" :model="form" :rules="rules" label-width="150rem">
        <el-form-item label="任务名称" prop="name" style="width: 500rem">
          <el-input v-model="form.name" maxlength="20" placeholder="请输入任务名称"></el-input>
        </el-form-item>

        <el-form-item label="开始时间" prop="start_at" style="width: 500rem">
          <el-date-picker v-model="form.start_at" placeholder="请选择开始时间" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
        </el-form-item>

        <el-form-item label="结束时间" prop="end_at" style="width: 500rem">
          <el-date-picker v-model="form.end_at" placeholder="请选择结束时间" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
        </el-form-item>
        <el-form-item label="应用校区" prop="school_id">
          <el-select v-model="form.school_id" placeholder="请选择应用校区">
            <el-option v-for="(item,key) in options[0]" :key="key" :label="item.school_name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="应用年级" prop="grade_id" style="width: 500rem">
          <el-select v-model="form.grade_id" placeholder="请选择应用年级">
            <el-option v-for="(item,key) in options[1]" :key="key" :label="item.grade_name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="合格率" prop="qualified" style="width: 500rem">
          <el-input v-model="form.qualified" maxlength="20" placeholder="请输入合格率" @input="(v) => (form.room_num = v.replace(/^0+(\d)|[^\d]+/g, ''))"></el-input>
          %
        </el-form-item>
      </el-form>
    </div>
    <!-- 底部按钮 -->
    
  
</edit-template>
</template>

<script>

export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  data() {
    return {
      form: {},
      rules: {
        name: [{required: true, message: '请输入任务名称', trigger: 'change'}],
        start_at: [{required: true, message: '请选择开始时间', trigger: 'change'}],
        end_at: [{required: true, message: '请选择结束时间', trigger: 'change'}],
        school_id: [{required: true, message: '请选择应用校区', trigger: 'change'}],
        grade_id: [{required: true, message: '请选择应用年级', trigger: 'change'}],
        qualified: [{required: true, message: '请输入合格率', trigger: 'change'}, {pattern: /^(?:[1-9][0-9]?|100)$/, message: '合格率仅可输入1-100的整数。', trigger: 'change'}],
      },
      options: [[], []]
    };
  },

  methods: {
    save(form) {
      this.$refs[form].validate((valid, obj) => {
        if (valid) {
          this.$_axios2.post('api/work/talk/store', this.form).then(res => {
            console.log(res)
            if (res.data.status === 0) {
              this.$message({
                message: res.data.msg,
                type: 'success',
              })
              this.$router.back()
            } else this.$message({
              message: res.data.msg,
              type: 'error',
            })
          })
        }
      });
    },
    open() {
      this.$confirm('是否取消新增', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$router.back();
      }).catch(() => {
      });
    },
  },

  created() {
    this.$_axios2.get('api/sub/substitute/school').then(res => {
      this.options[0] = res.data.data
      this.$forceUpdate()
    })
    this.$_axios2.get('api/common/screen/grade').then(res => {
      this.options[1] = res.data.data
      this.$forceUpdate()
    })
  }
};
</script>

<style lang="scss" scoped>
.checkbox {
  min-width: 100rem;
  margin: 10rem;
}

::v-deep .el-input--small .el-input__inner {
  width: 300rem;
  height: 40rem;
  color: #999;
}

::v-deep .el-checkbox-group {
  overflow: scroll;
  max-height: 400rem !important;
}
</style>
